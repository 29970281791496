/* eslint-disable max-len */
import { envVal } from '@root/config/config';
import moment from 'moment';
import { getStreamDescription, getStreamTitle } from "@kursk/content/contentUtils";

const year = new Date().getFullYear();

export const siriusContent = {
  timezone: 'Europe/Moscow',
  yandexMapState: {
    center: [55.751574, 37.573856],
    zoom: 10,
  },
  locale: { _id: 175 },
  title: 'Умный туризм',
  subTitle: 'Туристический портал ФТ Сириус',
  mainBanner: {
    title: 'Умный туризм',
    text: 'Это множество программ по самым разным профилям от информационных технологий и естественных наук до делового этикета, гончарного мастерства и сити-фермерства, а также культурный и спортивный досуг.',
    links: [
      { link: '/overviews', text: 'Что посмотреть' },
      { link: '/places', text: 'Что посетить' },
      { link: '/touristroutes', text: 'Экскурсии' },
    ]
  },
  agreementLink: '/docs/public_offer.pdf',
  // phone: '8 800 200-94-53',
  mail: 'support@sirius-tourism.ru',
  socials: {
    vk: 'https://vk.com/sirius.tourism',
    telegram: 'https://t.me/umniyturizm',
  },
  header: {
    links: [
      { title: 'Афиша', to: '/events' },
      { title: 'Места', to: '/places' },
      { title: 'Экскурсии', to: '/touristroutes' },
      { title: 'Обзоры', to: '/overviews' },
      { title: 'Сириус', to: '/overviews/7/federalnaya-territoriya-sirius' }
    ]
  },
  footer: {
    title: 'Сириус. Умный туризм',
    systemName: `© ${year}. Сириус. Умный туризм. Все права защищены.`,
    links: [
      { title: 'Афиша', to: '/events' },
      { title: 'Места', to: '/places' },
      { title: 'Экскурсии', to: '/touristroutes' },
      { title: 'Обзоры', to: '/overviews' },
      { title: 'Сириус', to: '/overviews/7/federalnaya-territoriya-sirius' }
    ],
  },
  analytics: {
    gtm: envVal({ production: 'G-6ZZ4F52FNC' }, null),
    // pixel: envVal({ production: '20552' }, null),
    yandex: envVal({ production: 88497661, staging: 88563919 }, null)
  },
  entitiesNames: {
    touristroutes: {
      nominative: 'экскурсия',
      plural: 'экскурсии',
      genitive: 'экскурсию',
      pluralGenitive: 'экскурсий'
    },
    routes: {
      nominative: 'экскурсия',
      plural: 'экскурсии',
      genitive: 'экскурсию',
      pluralGenitive: 'экскурсий'
    }
  },
  seo: {
    pages: {
      index: {
        title: () => 'Сириус. Умный туризм - Туристический портал Краснодарского края',
        description: () => 'Туристический портал Сочи: события, места, экскурсии, обзоры. Сириус. Умный туризм',
        titleOg: () => 'Сириус. Умный туризм - Туристический портал Краснодарского края'
      },
      events: {
        title: () => `Куда сходить в Сочи ${year}. Афиша ближайших мероприятий Сочи. Интересные развлекательные события. Сириус. Умный туризм`,
        description: () => 'Куда можно пойти в Сочи ✅. Афиша интересных мероприятий в Краснодарском крае. Подробная информация о событиях: ⭐ описания, места, расписания, ☎ телефоны. Сириус. Умный туризм',
        titleOg: () => '',
      },
      places: {
        title: () => `Все интересные места и достопримечательности Сочи ${year}. Сириус. Умный туризм`,
        description: () => `Интересные и необычные достопримечательности ${year} — описания, цены на билеты, адреса, режим работы, фотографии, адреса на карте. Сириус. Умный туризм`,
        titleOg: () => '',
      },
      routes: {
        title: () => `Туристические экскурсии в Сочи ${year}. Сириус. Умный туризм`,
        description: () => `Лучшие туристические экскурсии, которые можно исходить пешком или проехать на автомобиле. Карты туристических экскурсий, описание, необычные идеи для путешествия в Сочи ${year}. Сириус. Умный туризм`,
        titleOg: () => '',
      },
      overviews: {
        title: () => `Обзоры ${year} — Сочи. Сириус. Умный туризм`,
        description: () => `Обзоры лучших событий, мест ${year} — Сочи. Сириус. Умный туризм`,
        titleOg: () => '',
      },
      streams: {
        title: () => 'Трансляции на сайте. Сириус. Умный туризм',
        description: () => 'Все трансляции и площадки их проведения, виртуальные залы, виртуальные музеи, события. Видео о регионе. Краснодарский край. Сириус. Умный туризм',
        titleOg: () => '',
      },
      ticketsBundles: {
        title: () => 'Абонементы. Сириус. Умный туризм',
        description: () => 'Абонементы в Краснодарском крае. Сириус. Умный туризм',
        titleOg: () => '',
      },
      movies: {
        title: () => `Киноафиша в Сочи с расписанием сеансов, ценой на билеты, местами проведения | Культурный регион`,
        description: () => `Киноафиша Сочи всех кинотеатров с указанием точного места и времени. На странице можете узнать цену и приобрести билеты на фильм. Можете просмотреть афишу в Русиче, Мегагринне, Ситимолле, Победа, Рио, Синема парк на любой день с удобным календарем`,
        titleOg: () => '',
      },
      singleEvent: {
        title: ({ entity }) => `${entity.name} — описание, программа мероприятия, дата, время. Адрес места проведения — ${entity.address}. Афиша`,
        description: ({ entity }) => `${entity.name}. ✅ Место проведения — ${entity.placeName}, ✅ дата проведения — ${entity.eventDate ? moment(entity.eventDate).format('D MMMM YYYY') : ''}, ✅ адрес места проведения — ${entity.address}. Подробная информация: описание, фото. Официальный сайт — Сириус. Умный туризм`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singlePlace: {
        title: ({ entity }) => `${entity.name}. Адрес — ${entity.address}. Фото, режим работы, афиша и события. Сириус. Умный туризм`,
        description: ({ entity }) => `${entity.name}. Адрес — ${entity.address} Подробная информация: описание, адрес на карте, время работы, телефон, фотографии. Все туристические места и достопримечательности на сайте — Сириус. Умный туризм`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleRoute: {
        title: ({ entity }) => `${entity.name}. Подробная информация: описание, адреса мест на карте, фото. Сириус. Умный туризм`,
        description: ({ entity }) => `${entity.name}. Лучшие туристические экскурсии, которые можно исходить пешком или проехать на автомобиле в Краснодарском крае. Сириус. Умный туризм`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleOverview: {
        title: ({ entity }) => `${entity.name}. Сириус. Умный туризм`,
        description: ({ entity }) => `Обзор - ${entity.name}. Сириус. Умный туризм`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleStream: {
        title: ({ entity }) => getStreamTitle(entity),
        description: ({ entity }) => getStreamDescription(entity, 'Сириус. Умный туризм'),
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleTicketsBundle: {
        title: ({ entity }) => `Абонемент ${entity?.name}. Сочи. Сириус. Умный туризм`,
        description: ({ entity }) => `Абонемент ${entity?.name}. Купить билеты в Сочи. Сириус. Умный туризм`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      pushkincard: {
        title: () => `Пушкинская карта — АИС «Культурный регион»`,
        description: () => '',
        titleOg: () => `Пушкинская карта — АИС «Культурный регион»`,
      },
      search: {
        title: ({ searchQuery }) => `Поиск событий, мест, маршрутов, обзоров,
        ресторанов, гостиниц, абонементов по запросу «${searchQuery}»`,
        description: ({ searchQuery }) => `События, места, маршруты, обзоры,
        рестораны, гостиницы, абонементы по запросу «${searchQuery}»`,
      },
      faq: {
        title: () => `Часто задаваемые вопросы . Официальный сайт — Сириус`,
        description: () => 'Часто задаваемые вопросы . Официальный сайт — Сириус',
      }
    },
  },
};
