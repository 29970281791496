/* eslint-disable max-len */
import { envVal } from '@root/config/config';
import moment from 'moment';
import { getStreamPlaceOrEvent } from "@kursk/content/contentUtils";
import { capitalize } from 'lodash';

const year = new Date().getFullYear();

const tagPageDefaultSeo = {
  title: ({ tagName }) => `${tagName[0].toUpperCase() + tagName.slice(1)} в Луганске ${year}. Официальный сайт Культура Луганщины`,
  description: ({ tagName }) => `${tagName[0].toUpperCase() + tagName.slice(1)} в Луганске. Подробная информация о событиях: описания, места, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)`,
  titleOg: () => '',
  h1: ({ tagName }) => tagName
};
const tagPageDefaultSeoEvents = {
  title: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} в Луганске ${year}. Официальный сайт Культура Луганщины`,
  description: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} в Луганске. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Луганской Народной Республики (ЛНР)`,
  titleOg: () => '',
};
const tagPageDefaultSeoPlaces = {
  title: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} Луганска. Официальный сайт Культура Луганщины`,
  description: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} в Луганске. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Луганской Народной Республики (ЛНР)`,
  titleOg: () => '',
};

export const lnrContent = {
  timezone: 'Europe/Moscow',
  yandexMapState: {
    center: [48.58, 39.25],
    zoom: 9,
  },
  locale: { _id: 2870 },
  title: 'Культура Луганщины',
  subTitle: 'Культурный портал Луганской Народной Республики (ЛНР)',
  mainBanner: {
    hide: true,
    title: '',
    text: '',
    links: []
  },
  useBvi: envVal({ staging: false, development: false }, false),
  phone: '8 800 707-34-32',
  mail: 'support@lpr-culture.ru',
  agreementLink: '/docs/public_offer_21_02_2024.pdf',
  socials: {
    ok: '',
    vk: 'https://vk.com/mklnr',
    yaDzen: '',
    telegram: 'https://t.me/MK_LNR',
    facebook: '',
    instagram: '',
  },
  socialTitles: {
    vk: 'вконтакте',
    telegram: 'Telegram',
  },
  subscribeForm: {
    title: 'Узнавайте новости культуры первыми',
  },
  header: {
    links: [
      {
        title: 'Афиша',
        relativeLink: '/events',
        links: [
          { title: 'Все события', to: '/events' },
          { title: 'Пушкинская карта', to: '/events/tag-pushkinskaya-karta' },
          { title: 'Концерты и шоу', to: '/events/tag-koncerty-i-shou' },
          { title: 'Спектакли', to: '/events/tag-spektakli' },
          { title: 'Выставки', to: '/events/tag-vystavki' },
          { title: 'Мастер-классы', to: '/events/tag-master-klassy' },
          { title: 'Кино', to: '/movies' },
          { title: 'Фестивали', to: '/events/tag-festivali' },
          { title: 'Праздники', to: '/events/tag-prazdniki' }
        ]
      },
      { title: 'Места', to: '/places' },
      { title: 'Обзоры', to: '/overviews' },
      { title: 'Live', to: '/streams' },
    ],
  },
  footer: {
    title: 'Культура Луганщины',
    systemName: `© ${year}. Культура Луганщины. Все права защищены.`,
    showProCultureLogo: true,
    links: [
      { title: 'Афиша', to: '/events' },
      { title: 'Абонементы', to: '/ticketsbundles' },
      { title: 'Места', to: '/places' },
      { title: 'Обзоры', to: '/overviews' },
    ]
  },
  analytics: {
    yandex: envVal({ production: '96856763' }, null),
    gtm: envVal({ production: '' }, null),
    pixel: envVal({ production: '31951' }, null),
    goals: {
      paidVisit: 'events_visit_lnr',
      paidBuy: 'buy_ticket_lnr',
      freeVisit: 'events_visits_lnr',
      freeNoTicketsVisit: 'noactive_free_lnr',
      freeBuy: 'freeandzero_lnr',
      externalLinkVisit: 'events_visit_redirect_lnr',
      externalLinkBuy: 'buy_redirect_lnr',
      ticketsAdd: 'entity_ticket_add',
    }
  },
  unisenderNewsPopup: {
    id: envVal({ staging: '', }, null),
  },
  componentsConfig: {
    header: 'default',
    footer: 'default',
    heroBlock: 'banner',
    socialCardIconVk: 'v2',
    socialCardIconTg: 'v2',
    pages: {
      index: {},
      places: {},
      singlePlace: {},
    },
  },
  seo: {
    pages: {
      index: {
        title: () => 'Культурный портал Луганской Народной Республики (ЛНР) — Культура Луганщины. lpr-culture.ru',
        description: () => 'Культурный портал Луганской Народной Республики (ЛНР): события, места, обзоры, гостиницы и рестораны, трансляции',
        titleOg: () => 'Культурный портал Луганской Народной Республики (ЛНР) — Культура Луганщины'
      },
      events: {
        title: () => `Куда сходить в Луганске ${year}. Афиша ближайших мероприятий Луганска. Интересные развлекательные события. Официальный сайт Культура Луганщины`,
        description: () => 'Куда можно пойти в Луганске. Афиша интересных мероприятий в Луганской Народной Республике (ЛНР). Подробная информация о событиях: описания, места, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)',
        titleOg: () => '',
      },
      'tagPageDefaultSeoevents': tagPageDefaultSeoEvents,
      places: {
        title: () => `Все интересные места и достопримечательности Луганска ${year}. Официальный сайт Культура Луганщины`,
        description: () => `Интересные и необычные достопримечательности в Луганске ${year} — описания, цены на билеты, адреса,
         режим работы, фотографии, адреса на карте. Официальный сайт Луганской Народной Республики (ЛНР)`,
        titleOg: () => ''
      },
      'tagPageDefaultSeoplaces': tagPageDefaultSeoPlaces,
      'tagPageDefaultSeo': tagPageDefaultSeo,
      overviews: {
        title: () => `Обзоры ${year} — Луганская Народная Республика (ЛНР). Официальный сайт Культура Луганщины`,
        description: () => `Обзоры лучших событий, мест ${year} — Луганск. Официальный сайт Луганской Народной Республики (ЛНР)`,
        titleOg: () => ''
      },
      streams: {
        title: () => 'Трансляции на сайте. Официальный сайт Культура Луганщины',
        description: () => 'Все трансляции и площадки их проведения. Официальный сайт Луганской Народной Республики (ЛНР)',
        titleOg: () => '',
        h1: () => ``,
      },
      ticketsBundles: {
        title: () => 'Абонементы. Официальный сайт Культура Луганщины',
        description: () => 'Абонементы в Луганске. Официальный сайт Луганской Народной Республики (ЛНР)',
        titleOg: () => ''
      },
      movies: {
        title: () => `Киноафиша в Луганске | Афиша мероприятий с точными датами на ${year} год — Официальный сайт Культура Луганщины`,
        description: () => `Киноафиша в Луганске на ${year} год ✅ Афиша интересных мероприятий в Луганске. Подробная информация о событиях: ⭐ описания, места, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)`,
        titleOg: () => ''
      },
      singleEvent: {
        title: ({ entity }) => `${entity.name} — описание, программа мероприятия, дата, время.
         Адрес места проведения — ${entity.address}. Официальный сайт Культура Луганщины`,
        description: ({ entity }) => `${entity.name}. Место проведения — ${entity.placeName},
          дата проведения — ${entity.eventDate ? moment(entity.eventDate).format('D MMMM YYYY') : ''}, адрес места проведения — ${entity.address}.
          Подробная информация: описание, фото. Официальный сайт — Луганской Народной Республики (ЛНР)`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singlePlace: {
        title: ({ entity }) => `${entity.name}. Адрес — ${entity.address}.
        Фото, режим работы, афиша и события. Официальный сайт Культура Луганщины`,
        description: ({ entity }) => `${entity.name}. Адрес — ${entity.address} Подробная информация: описание,
         адрес на карте, время работы, телефон, фотографии. Все достопримечательности на сайте Луганской Народной Республики (ЛНР)`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleOverview: {
        title: ({ entity }) => `${entity.name}. Официальный сайт Культура Луганщины`,
        description: ({ entity }) => `Обзор — ${entity.name}. Официальный сайт Луганской Народной Республики (ЛНР)`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleStream: {
        title: ({ entity }) => `Трансляция ${entity.name}. ${getStreamPlaceOrEvent(entity)}Прямая трансляция на официальном сайте Культура Луганщины`,
        description: ({ entity }) => `${entity.name}. ${getStreamPlaceOrEvent(entity)}Дата и время проведения — ${entity.liveDate ? moment(entity.liveDate).format('D MMMM YYYY H:mm') : ''} на официальном сайте Луганской Народной Республики (ЛНР)`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleTicketsBundle: {
        title: ({ entity }) => `Абонемент ${entity?.name}. Официальный сайт Культура Луганщины`,
        description: ({ entity }) => `Абонемент ${entity?.name}. Купить билеты в Луганске. Официальный сайт Луганской Народной Республики (ЛНР)`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleMovie: {
        title: ({ entity }) => `Фильм ${entity?.name} в Луганске  — афиша, цена на билеты,
         расписание, описание, место проведения | Официальный сайт Культура Луганщины`,
        description: () => `Киноафиша Луганска с точным расписанием киносеансов во всех
         кинотеатрах города. Узнать цену и купить билет можно на странице с выбором фильма.
          На сайте можно узнать точное время и дату на сегодня, завтра или в другой день`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      pushkincard: {
        title: () => `Пушкинская карта — Культура Луганщины`,
        description: () => 'Пушкинская карта — Официальный сайт Луганской Народной Республики (ЛНР)',
        titleOg: () => `Пушкинская карта — Культура Луганщины`,
      },
      main: { title: 'Культурный портал Луганской Народной Республики (ЛНР) — Культура Луганщины.' },
      museums: {
        title: () => `Виртуальные туры по музеям, ${year} — Луганск | Официальный сайт Культура Луганщины`,
        description: () => `Виртуальные туры по музеям Луганска,
        ${year} — посетите лучшие выставки, не выходя из дома. Официальный сайт Луганской Народной Республики (ЛНР)`
      },
      singleMuseum: {
        title: ({ entity }) => ` Виртуальный тур по музею, ${year}. ${entity.name}. Официальный сайт Культура Луганщины`,
        description: ({ entity }) => `Виртуальный тур по музею, ${year} — посетите выставку, не выходя из дома. Музей — ${entity.name}. Официальный сайт Луганской Народной Республики (ЛНР)`
      },
      artefacts: {
        title: () => `Дополненная реальность, ${year} — Луганск | Официальный сайт Культура Луганщины`,
        description: () => `Гиды дополненной реальности и интерактивные мероприятия,
        ${year} — Луганска. Официальный сайт Луганской Народной Республики (ЛНР)`
      },
      singleArtefact: {
        title: ({ entity }) => `Гид дополненной реальности, ${year} — ${entity?.name}. Официальный сайт Культура Луганщины`,
        description: ({ entity }) => `Гид дополненной реальности, ${year} — ${entity?.name}. Официальный сайт Луганской Народной Республики (ЛНР)`,
      },
      search: {
        title: ({ searchQuery }) => `Поиск событий, мест, обзоров, абонементов по запросу «${searchQuery}»`,
        description: ({ searchQuery }) => `События, места, обзоры, абонементы по запросу «${searchQuery}»`,
      },
      faq: {
        title: () => `Часто задаваемые вопросы . Официальный сайт Культура Луганщины`,
        description: () => 'Часто задаваемые вопросы . Официальный сайт Луганской Народной Республики (ЛНР)',
      },
      // Порядок вложенности необходимо соблюдать, иначе функция получения сео метаданных отработает неправильно
      // В единсвенном числе - фильтр (locale, place, date, ...), с приставкой Page(eventsPage, hotelsPage, ...) - страница, для которой метаданные
      // Примеры:
      // locale->date->eventsPage: Фильтр район + фильтр дата для страницы событий
      // place->tag->eventsPage->default: Фильтр место + тэг для страницы событий
      // tag->eventsPage->pushkinskaya-karta: Тэг Пушкцинская карта для страницы событий
      filters: {
        locale: {
          eventsPage: {
            title: ({ localeName }) => `${localeName} — афиша ${year}. Интересные развлекательные события. Официальный сайт Культура Луганщины`,
            description: ({ localeName }) => `${localeName} — афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)`,
            h1: ({ localeName }) => `${localeName} - афиша`,
          },
          placesPage: {
            title: ({ localeName }) => `Интересные места — ${localeName} ${year}. Официальный сайт Культура Луганщины`,
            description: ({ localeName }) => `Все интересные места и достопримечательности — ${localeName} ${year} — описания, цены на билеты, режим работы, фотографии, адреса на карте. Официальный сайт Луганской Народной Республики (ЛНР)`,
            h1: ({ localeName }) => `Места - ${localeName}`,
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(localeName)} — ${tagName}. Интересные развлекательные события. Официальный сайт Культура Луганщины`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} — ${localeName}. Подробная информация о мероприятиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Луганской Народной Республики (ЛНР)`,
                h1: ({ localeName, tagName }) => `${capitalize(localeName)} - ${tagName}`,
              }
            },
            placesPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(tagName)} — ${localeName} ${year}. Интересные места. Официальный сайт Культура Луганщины`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} — ${localeName}. Подробная информация о месте: описания, фото, адрес на карте, время работы. Официальный сайт Луганской Народной Республики (ЛНР)`,
                h1: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}`,
              }
            },
          },
          place: {
            eventsPage: {
              title: ({ placeName }) => `${placeName} — афиша ${year}. Интересные развлекательные события. Официальный сайт Культура Луганщины`,
              description: ({ placeName }) => `${placeName} — афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)`,
              h1: ({ placeName }) => `${placeName} - афиша`,
            }
          },
          date: {
            eventsPage: {
              title: ({ localeName, date }) => `${capitalize(localeName)} — афиша на ${date} ${year} года. Интересные развлекательные события. Официальный сайт Культура Луганщины`,
              description: ({ localeName, date }) => `${capitalize(localeName)} — афиша на ${date} ${year} года. Интересные мероприятия в Луганске. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)`,
              h1: ({ localeName, date }) => `${capitalize(localeName)} - афиша на ${date}`,
            }
          },
          interim: {
            eventsPage: {
              title: ({ localeName, interim, formattedDate, formattedYear }) => `${capitalize(localeName)} — афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные события. Официальный сайт Культура Луганщины`,
              description: ({ localeName, interim, formattedDate, formattedYear }) => `${capitalize(localeName)} — афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)`,
              h1: ({ localeName, interim, formattedDate }) => `${capitalize(localeName)} - афиша на ${interim}, ${formattedDate}`,
            }
          }
        },
        place: {
          eventsPage: {
            title: ({ placeName }) => `${placeName} — афиша ${year}. Интересные развлекательные события. Официальный сайт Культура Луганщины`,
            description: ({ placeName }) => `${placeName} — афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)`,
            h1: ({ placeName }) => `${placeName} - афиша`,
          },
          streamsPage: {
            title: ({ placeName }) => `Трансляции — ${placeName} ${year}. Официальный сайт Культура Луганщины`,
            description: ({ placeName }) => `Трансляции — ${placeName} ${year}. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт Луганской Народной Республики (ЛНР)`,
            h1: ({ placeName }) => `Трансляции - ${placeName}`
          },
          date: {
            eventsPage: {
              title: ({ placeName, date }) => `${capitalize(placeName)} — афиша на ${date} ${year} года. Интересные развлекательные события. Официальный сайт Культура Луганщины`,
              description: ({ placeName, date }) => `${capitalize(placeName)} — афиша на ${date} ${year} года. Интересные мероприятия в Луганске. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)`,
              h1: ({ placeName, date }) => `${capitalize(placeName)} - афиша на ${date}`
            },
            streamsPage: {
              title: ({ placeName, date }) => `${capitalize(placeName)} — трансляции на ${date} ${year} года. Интересные развлекательные трансляции. Официальный сайт Культура Луганщины`,
              description: ({ placeName, date }) => `${capitalize(placeName)} — трансляции на ${date} ${year} года. Интересные трансляции. Подробная информация о трансляциях: описания, время проведения, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)`,
              h1: ({ placeName, date }) => `${capitalize(placeName)} - трансляции на ${date}`
            }
          },
          interim: {
            eventsPage: {
              title: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} — афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные события. Официальный сайт Культура Луганщины`,
              description: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} — афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)`,
              h1: ({ placeName, interim, formattedDate }) => `${capitalize(placeName)} - афиша на ${interim}, ${formattedDate}`,
            },
            streamsPage: {
              title: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} — трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные трансляции. Официальный сайт Культура Луганщины`,
              description: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} — трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Интересные трансляции. Подробная информация о трансляциях: описания, время проведения, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)`,
              h1: ({ placeName, interim, formattedDate }) => `${capitalize(placeName)} - трансляции на ${interim}, ${formattedDate}`,
            }
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, placeName }) => `${capitalize(placeName)} — ${tagName}. Интересные развлекательные события. Официальный сайт Культура Луганщины`,
                description: ({ tagName, placeName }) => `${capitalize(placeName)} — ${tagName}. Подробная информация о мероприятиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Луганской Народной Республики (ЛНР)`,
                h1: ({ tagName, placeName }) => `${capitalize(placeName)} - ${tagName}`,
              }
            }
          }
        },
        date: {
          eventsPage: {
            title: ({ formattedDate }) => `Афиша Луганска на ${formattedDate} года. Интересные развлекательные события в Луганске. Официальный сайт Культура Луганщины`,
            description: ({ formattedDate }) => `Афиша в Луганске на ${formattedDate} года. Интересные мероприятия Луганска. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)`,
            h1: ({ date }) => `Афиша на ${date}`,
          },
          moviesPage: {
            title: ({ formattedDate }) => `Киноафиша в Луганске на ${formattedDate}. Кино в Луганске — ${formattedDate}. Официальный сайт Культура Луганщины`,
            description: ({ formattedDate }) => `Кино в Луганске — ${formattedDate} ✅ Киноафиша в Луганске на ${formattedDate}. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)`,
            h1: ({ date }) => `Киноафиша на ${date}`,
          },
          streamsPage: {
            title: ({ formattedDate }) => `Трансляции ${formattedDate} года. Официальный сайт Культура Луганщины`,
            description: ({ formattedDate }) => `Трансляции — ${formattedDate} года. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт Луганской Народной Республики (ЛНР)`,
            h1: ({ date }) => `Трансляции - ${date}`,
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, formattedDate }) => `${capitalize(tagName)} — афиша на ${formattedDate} года. Интересные развлекательные события. Официальный сайт Культура Луганщины`,
                description: ({ tagName, formattedDate }) => `${capitalize(tagName)} — афиша на ${formattedDate} года. Интересные мероприятия - Луганск. Подробная информация о мероприятиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)`,
                h1: ({ tagName, date }) => `${capitalize(tagName)} - афиша на ${date}`,
              }
            },
          },
          genre: {
            moviesPage: {
              title: ({ genreName, formattedDate }) => `Киноафиша в жанре ${genreName} на ${formattedDate} года. Официальный сайт Культура Луганщины`,
              description: ({ genreName, formattedDate }) => `Кино в жанре ${genreName} на ${formattedDate} года. Киноафиша на ${formattedDate} года. Подробная информация о кино: описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)`,
              h1: ({ genreName, date }) => `Киноафиша в жанре ${genreName} на ${date}`,
            }
          }
        },
        interim: {
          eventsPage: {
            title: ({ interim, formattedDate }) => `Афиша на ${interim}, ${formattedDate} в Луганске. Интересные развлекательные события в Луганске. Официальный сайт Культура Луганщины`,
            description: ({ interim, formattedDate }) => `Афиша Луганска на ${interim}, ${formattedDate}. Интересные мероприятия Луганска. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)`,
            h1: ({ interim, formattedDate }) => `Афиша на ${interim}, ${formattedDate}`,
          },
          moviesPage: {
            title: ({ interim, formattedDate, formattedYear }) => `Киноафиша на ${interim}, ${formattedDate} ${formattedYear} года в Луганске. Кино в Луганске ${interim}, на ${formattedDate} ${formattedYear} года. Официальный сайт Культура Луганщины`,
            description: ({ interim, formattedDate, formattedYear }) => `Кино в Луганске на ${interim}, ${formattedDate} ${formattedYear} года ✅ Киноафиша в Луганске ${interim}, ${formattedDate} ${formattedYear} года. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)`,
            h1: ({ interim, formattedDate }) => `Киноафиша на ${interim}, ${formattedDate}`,
          },
          streamsPage: {
            title: ({ interim, formattedDate, formattedYear }) => `Трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Официальный сайт Культура Луганщины`,
            description: ({ interim, formattedDate, formattedYear }) => `Трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт Луганской Народной Республики (ЛНР)`,
            h1: ({ interim, formattedDate }) => `Трансляции на ${interim}, ${formattedDate}`,
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} — афиша на ${interim}, ${formattedDate} ${year} года. Интересные развлекательные события. Официальный сайт Культура Луганщины`,
                description: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} — афиша на ${interim}, ${formattedDate} ${year} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)`,
                h1: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} - афиша на ${interim}, ${formattedDate}`,
              }
            },
          },
          genre: {
            moviesPage: {
              title: ({ genreName, interim, formattedDate }) => `Киноафиша в жанре ${genreName} на ${interim}, ${formattedDate} ${year} года. Официальный сайт Культура Луганщины`,
              description: ({ genreName, interim, formattedDate }) => `Кино в жанре ${genreName} на ${interim}, ${formattedDate} ${year} года. Киноафиша на ${interim} ${formattedDate} ${year} года. Подробная информация о кино: описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)`,
              h1: ({ genreName, interim, formattedDate }) => `Киноафиша в жанре ${genreName} на ${interim}, ${formattedDate}`,
            }
          }
        },
        tag: {
          eventsPage: {
            'pushkinskaya-karta': {
              title: () => `Пушкинская карта ${year} в Луганске. Купить билеты по Пушкинской карте в Луганске. Официальный сайт Культура Луганщины`,
              description: () => `Афиша мероприятий по Пушкинской карте Луганска. Купить билеты по Пушкинской карте в кино, на концерты, на спектакли и другие мероприятия. Официальный сайт Луганской Народной Республики (ЛНР)`,
              h1: () => `Пушкинская карта`
            },
            'koncerty-i-shou': {
              title: () => `Концерты в Луганске ${year}. Афиша концертов Луганска. Официальный сайт Культура Луганщины`,
              description: () => `Расписания концертов в Луганске. Купить билеты на концерты в Луганске. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Луганской Народной Республики (ЛНР)`,
              h1: () => `Концерты и шоу`
            },
            'spektakli': {
              title: () => `Спектакли в Луганске ${year}. Купить билеты на спектакль в Луганске. Официальный сайт Культура Луганщины`,
              description: () => `Афиша спектаклей в Луганске. Купить билеты на спектакль в Луганске. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Луганской Народной Республики (ЛНР)`,
              h1: () => `Спектакли`
            },
            'detyam': {
              title: () => `Детская афиша в Луганске ${year}. Куда пойти с ребенком в Луганске. Официальный сайт Культура Луганщины`,
              description: () => `Детские мероприятия в Луганске. Куда пойти с ребенком в Луганске. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Луганской Народной Республики (ЛНР)`,
              h1: () => `Детям`
            },
            default: tagPageDefaultSeo
          },
          streamsPage: {
            default: {
              title: ({ tagName }) => `Трансляции — ${tagName}. Официальный сайт Культура Луганщины`,
              description: ({ tagName }) => `Трансляции — ${tagName}. Прямые трансляции: смотреть онлайн и бесплатно Официальный сайт Луганской Народной Республики (ЛНР)`,
              h1: ({ tagName }) => `Трансляции - ${tagName}`,
            }
          },
        },
        genre: {
          moviesPage: {
            title: ({ genreName }) => `Киноафиша в Луганске, жанр — ${genreName}. Кино в Луганске, жанр — ${genreName}. Официальный сайт Культура Луганщины`,
            description: ({ genreName }) => `Кино в Луганске, жанр — ${genreName} ✅ Киноафиша в Луганске, жанр — ${genreName}. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Луганской Народной Республики (ЛНР)`,
            h1: ({ genreName }) => `Киноафиша, жанр - ${genreName}`
          },
        }
      },
    },
  },
};
