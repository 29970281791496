export type THEMES = 'kursk' | 'sirius' | 'bel' | 'primorsky' | 'sakhalin' | 'vladimir' | 'pprog' | 'sochi' | 'lnr';

export type THEME_HASH<T = any> = {
  [p in THEMES]: T;
}

type THEME_VALUE_TYPE<T> = Partial<THEME_HASH<T>> & { default: T | any };

export function themeValue<T = any>(values: THEME_VALUE_TYPE<T>): T {
  const region = process.env.NEXT_PUBLIC_REGION_THEME;
  if (values[region] !== undefined) {
    return values[region];
  }
  return values.default;
}
